<template>
  <div>
    <div class="container-fluid -wrap">
      <div class="purchase-list">
        <div class="row">
          <!-- <div class="col-12 icon-wrpp"> -->
          <div class="col-12 mt-5 list-view-icon">
            <i
              @click="switchViewMode('list')"
              class="fas fa-bars cursor-pointer"
            ></i>
            <i @click="switchViewMode('grid')" class="fas fa-th pl-2"></i>
          </div>
          <div
            class="
              col-lg-12
              m-0
              p-0
              d-flex
              justify-content-between
              align-items-center
            "
          >
            <div class="col-lg-8 mt-0 d-flex">
              <div
                data-v-0daac4f0=""
                class="filer-area-box d-block py-3 position-relative"
              >
                <label
                  data-v-0daac4f0=""
                  class="txt__sm font-weight-bold mb-2"
                ></label>
                <input
                  data-v-0daac4f0=""
                  type="search"
                  :class="[
                    search.length > 0 ? 'form-control' : 'form-control-search form-control',
                  ]"
                  placeholder="Search by"
                  v-model="search"
                />
              </div>

              <div
                class="
                  col-lg-4
                  multi-select-wrap
                  mt-5
                  mb-0
                  d-flex
                  justify-content-center
                "
              >
                <multiselect
                  v-model="industry"
                  :options="industries"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :class="[industry.length > 0]"
                  placeholder="Select Industry"
                >
                </multiselect>
              </div>
            </div>

            <div class="col-lg-4 mt-4">
              <nav data-v-0daac4f0="" class="listNav p-0">
                <div
                  data-v-0daac4f0=""
                  class="listNav__text d-flex align-items-center"
                >
                  <button
                    data-v-0daac4f0=""
                    class="list-filter-toggler d-xl-none mr-2"
                  ></button>
                </div>
                <div
                  data-v-0daac4f0=""
                  class="listNav__filter d-flex align-items-center"
                >
                  <p data-v-0daac4f0="" class="txt__xs mr-4 current-list-total">
                    <strong data-v-0daac4f0="">10</strong> of
                    <strong data-v-0daac4f0="">416</strong> results
                  </p>
                  <select
                    data-v-0daac4f0=""
                    class="custom-select custom-select--list"
                    v-model="sort"
                    @click="getPurchasedProducts"
                  >
                    <option data-v-0daac4f0="" selected="selected" value="">
                      Sort By
                    </option>
                    <option data-v-0daac4f0="" value="asc">
                      Price low to high
                    </option>
                    <option data-v-0daac4f0="" value="desc">
                      Price hight to low
                    </option>
                  </select>
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 mb-3">
            <div class="row">
              <div class="d-flex">
                <p class="label-succes"></p>
                <p class="pl-2 mr-2 success-level">Notification1</p>
              </div>
              <div class="d-flex">
                <p class="label-notification"></p>
                <p class="pl-2 notification-lable">Notification2</p>
              </div>
            </div>
          </div>
        </div>

        <div
          class="purchase-wrap position-relative"
          v-if="viewContent == 'list'"
        >
          <!-- <div class="trbages-box position-absolute"></div>
          <div class="trbages-box2 position-absolute"></div> -->
          <table class="table-wraping">
            <tr class="tr-badge">
              <div ></div>
              <th>Product Name</th>
              <th>Total Data</th>
              <th>Industry</th>
              <th>Tags</th>
              <th>Seller</th>
              <th>Purchase Date</th>
              <th>Review</th>
              <th>Price</th>
              <th>Action</th>
            </tr>
            <tr
              class="tr-bages border-bottom position-relative"
              v-for="(purchased, index) in PurchaseLists"
              :key="index"
            >
              <div class="tr-lavel-1 position-absolute"></div>
              <div class="tr-lavel-2 position-absolute"></div>
              <td class="width-defind">
                <span class="product-name">{{ purchased.title }}</span>
              </td>
              <td>{{ purchased.row }}</td>

              <td class="all-tags" v-if="purchased.industries.length > 0">
                <span
                  class="industry-part"
                  v-for="(industry, j) in purchased.industries"
                  :key="j"
                  >{{ industry }}</span
                >
              </td>

              <td class="all-tags" v-if="purchased.tags.length > 0">
                <span
                  class="tags"
                  v-for="(tag, j) in purchased.tags"
                  :key="j"
                  >{{ tag }}</span
                >
              </td>

              <td style="max-width: 80px">
                <div
                  class="
                    d-flex
                    flex-xl-row
                    flex-lg-column
                    flex-md-column
                    flex-sm-column
                    flex-column
                    user-icon
                  "
                >
                  <i class="fa fa-user pt-1 mr-2" aria-hidden="true"></i>
                  <span class="user-name">{{ purchased.seller }}</span>
                </div>
              </td>

              <td>{{ formatDate(purchased.date) }}</td>
              <td>
                <div class="d-flex" v-if="purchased.rating > 0">
                  <span v-for="rating in purchased.rating" :key="rating.id"
                    ><i
                      class="fa fa-star mr-1 mt-1"
                      aria-hidden="true"
                      style="color: #f7b908"
                    ></i
                  ></span>
                </div>
                <div class="d-flex" v-else>
                  <button
                    href="#"
                    class="btn-wrap mb-2"
                    @click="openReviewModal(purchased.product_id)"
                    :class="{ active: ReviewModalOpen }"
                  >
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </button>
                </div>
              </td>

              <td>{{ purchased.price }}</td>
              <td class="view-download-icon">
                <div
                  class="view-icon"
                  @click="viewProduct(purchased.product_id)"
                >
                  <i
                    class="fa fa-eye bg-primary mr-2 text-white p-1"
                    aria-hidden="true"
                  ></i>
                </div>
                <div
                  class="download-icon"
                  @click="downloadFile(purchased.product_id)"
                >
                  <i
                    class="fa fa-download bg-primary text-white p-1"
                    aria-hidden="true"
                  ></i>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div
          class="purchase-wrap-grid overflow-hidden"
          v-if="viewContent == 'grid'"
        >
          <div class="row">
            <div
              class="col-lg-4 p-3 box-wrap-area position-relative"
              v-for="(purchased, index) in PurchaseLists"
              :key="index"
            >
              <div class="lable1 position-absolute"></div>
              <div class="lable2 position-absolute"></div>
              <div class="bg-white wrapp-area-box">
                <div class="product__card__header">
                  <router-link
                    title="marketplaceDetails"
                    :to="{
                      path: '/marketplace-details',
                      query: { product: purchased.product_id },
                    }"
                    ><p class="txt__md font-weight-bold txt">
                      {{ purchased.title }}
                    </p></router-link
                  >
                  <div class="pb-1 d-flex justify-content-between">
                    <div>
                      <div
                        class="product__card__tags"
                        v-if="purchased.industries.length > 0"
                      >
                        <div
                          class="product-tag bg-primary text-white"
                          v-for="(industry, j) in purchased.industries"
                          :key="j"
                        >
                          {{ industry }}
                        </div>
                      </div>
                      <div
                        class="product__card__tags"
                        v-if="purchased.tags.length > 0"
                      >
                        <div
                          class="product-tag bg-success text-white"
                          v-for="(tag, j) in purchased.tags"
                          :key="j"
                        >
                          {{ tag }}
                        </div>
                      </div>
                    </div>
                    <div class="product__card__reviews__update">
                      <p class="txt__xs mb-2 text-muted">Puchase Date</p>
                      <p class="txt__xs">{{ formatDate(purchased.date) }}</p>
                    </div>
                  </div>
                </div>
                <div class="product__card__review mb-0 pb-0">
                  <div
                    class="
                      product__uploader
                      d-flex
                      justify-content-between
                      w-100
                    "
                  >
                    <div class="d-flex">
                      <div class="avatar">
                        {{ firstChar(purchased.seller) | capitalize }}
                      </div>
                      <div>
                        <a href="/user-profile"
                          ><p class="txt__sm font-weight-bold txt pt-1">
                            {{ purchased.seller }}
                          </p></a
                        >
                      </div>
                    </div>
                    <div class="contact-no">
                      <h6 class="txt__lg font-weight-bold mb-2">Total Data</h6>
                      <div class="d-flex align-items-center">
                        <p class="txt__xs font-weight-bold">
                          {{ purchased.row }}
                        </p>
                      </div>
                    </div>
                    <div class="pricetag">
                      <h6 class="txt__lg">Price</h6>
                      <div class="d-flex align-items-center">
                        <p class="txt__xs font-weight-bold">
                          {{ purchased.price }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="product__card__stats-wrap w-100 pl-5 pr-5">
                  <div class="form-row">
                    <div
                      class="
                        d-flex
                        justify-content-between
                        text-align
                        w-100
                        align-items-center
                      "
                    >
                      <div class="d-flex">
                        <div
                          class="view-icon-area"
                          @click="viewProduct(purchased.product_id)"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="currentColor"
                            class="
                              bi bi-eye
                              font-weight-bolder
                              text-primary
                              background-transparent
                              mr-2
                              ml-2
                            "
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                            />
                            <path
                              d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                            />
                          </svg>
                        </div>
                        <div
                          class="download-icon-wrap"
                          @click="downloadFile(purchased.product_id)"
                        >
                          <svg
                            data-v-0daac4f0=""
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                            class="
                              bi bi-cloud-arrow-down
                              text-primary text-bold
                            "
                          >
                            <path
                              data-v-0daac4f0=""
                              fill-rule="evenodd"
                              d="M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708l2 2z"
                            ></path>
                            <path
                              data-v-0daac4f0=""
                              d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      <div
                        class="
                          product__card__uploader
                          d-flex
                          justify-content-end
                          pr-0
                        "
                      >
                        <div class="d-flex" v-if="purchased.rating > 0">
                          <span
                            v-for="rating in purchased.rating"
                            :key="rating.id"
                            ><i
                              class="fa fa-star mr-1 mt-1"
                              aria-hidden="true"
                              style="color: #f7b908"
                            ></i
                          ></span>
                        </div>
                        <div class="d-flex" v-else>
                          <button
                            href="#"
                            class="btn-wrap mb-2"
                            @click="openReviewModal(purchased.product_id)"
                            :class="{ active: ReviewModalOpen }"
                          >
                            <i class="fa fa-plus mb-2" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MODAL -->
    <ReviewModal @REVIEW_VALUE_CHANGED="getPurchasedProducts" />
  </div>
</template>

<script>
import ReviewModal from "@/components/modal/ReviewModal";
import Multiselect from "vue-multiselect";
import { axiosP, axiosHeaderP } from "@/services/axiosinstance";

export default {
  components: {
    Multiselect,
    ReviewModal,
  },
  name: "PurchaseList",
  data() {
    return {
      industries: [],
      industry: [],
      viewContent: "list",
      PurchaseLists: [],
      status: "",
      search: "",
      sort: "",
      page: "",
    };
  },
  created() {
    this.getIndustries();
    this.getPurchasedProducts();
  },
  watch: {
    search: function (newVal, oldVal) {
      if (newVal.trim() != oldVal.trim()) {
        this.page = 1;
        // this.PurchaseLists = [];
        this.getPurchasedProducts();
      }
    },
    industry: function () {
      this.page = 1;
      // this.PurchaseLists = [];
      this.getPurchasedProducts();
    },
  },
  methods: {
    openReviewModal(id) {
      this.$modal.show("ReviewModal", {
        data: JSON.parse(JSON.stringify(id)),
      });
    },
    firstChar(seller) {
      if (seller) {
        return seller.charAt(0);
      } else {
        return;
      }
    },
    switchViewMode(viewType) {
      this.viewContent = viewType;
    },
    viewProduct(product_id) {
      this.$router.push("/marketplace-details?product=" + product_id);
    },
    formatDate(date) {
      if (date) {
        return this.$moment(date).format("YYYY-MM-DD");
      } else {
        return "N/A";
      }
    },
    async downloadFile(id) {
      this.formData = new FormData();
      this.formData.append("product_id", id);
      await axiosHeaderP
        .post("download-file", this.formData)
        .then((res) => {
          let url = res.data;
          const link = document.createElement("a");
          link.href = url;
          link.click();
        })
        .catch(() => {
          // console.log(err);
        });
    },
    async getPurchasedProducts() {
      this.formData = new FormData();
      this.formData.append("page", this.page);
      this.formData.append("limit", 10);
      this.formData.append("search", this.search);
      this.formData.append("sort_order", this.sort);
      this.formData.append("industry", this.industry);
      this.formData.append("sort_by", "price");
      await axiosHeaderP
        .post("purchased-products", this.formData)
        .then((res) => {
          if (res.data.status) {
            this.PurchaseLists = res.data.data;
            // this.load_more = res.data.pagination.load_more;
            // this.pagination = res.data.pagination;
          } else {
            this.PurchaseLists = [];
            // this.load_more = false;
          }
        })
        .catch(() => {
          this.PurchaseLists = [];
          // this.load_more = false;
          // console.log(err);
        });
    },
    async getIndustries() {
      this.formData = new FormData();
      await axiosP
        .post("all-industry-list", this.formData)
        .then((res) => {
          this.industries = res.data.industries;
        })
        .catch(() => {
          // console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.list-view-icon {
  color: #646464;
}
.wrapp-area-box {
  box-shadow: 0px 0px 18px 0px #80808029;
}
.lable1 {
  z-index: 999;
  top: 16px;
  left: 16px;
  width: 3px;
  height: 44%;
  background-color: #5b57b1;
}
.lable2[data-v-3bb6fd91] {
  z-index: 999;
  top: 49%;
  left: 16px;
  width: 3px;
  height: 46%;
  background-color: #f73859;
}
.pricetag h6 {
  font-size: 15px;
  font-weight: 600;
  line-height: 27px;
}

.view-icon-area {
  padding: 6px 30px;
  text-align: center;
  border-radius: 5px;
  margin-right: 10px;
}
.contact-no h6 {
  font-size: 13px;
  font-weight: 600 !important;
  line-height: 10px;
}
.multi-select-wrap[data-v-3bb6fd91] {
  margin-top: 38px !important;
  margin-left: 14px;
}
.tr-lavel-1 {
  width: 3px;
  height: 50%;
  background: #5b57b1;
}
.tr-lavel-2 {
  width: 3px;
  height: 50%;
  top: 50%;
  background: #f73859;
}
.user-name {
  font-size: 12px;
  text-align: left;
  color: rgb(3, 3, 3);
  font-weight: 500;
  line-height: 32px;
}
.industry-part {
  background-color: #0091ff;
  border-radius: 3px;
  color: #fff;
  margin: 3px;
  padding: 2px;
  display: inline-block;
}
.tags {
  background-color: #3bba5f;
  border-radius: 3px;
  color: #fff;
  margin: 3px;
  padding: 2px;
  display: inline-block;
}
.all-tags {
  max-width: 150px;
}
.customer-name {
  font-weight: 400;
  font-size: 12px;
}
.tr-badge {
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 20px;
  border-bottom: 1px solid #dee2e6;
}
.tr-bages {
  font-size: 11px;
  width: 100%;
}
.width-defind {
  max-width: 150px;
}
.purchase-list-wrap {
  padding: 0px 100px;
  background: #fff;
  padding-bottom: 50px;
}
.box_info {
  background: #f5f5f5;
}
.product-name {
  font-weight: bold;
  font-size: 12px;
}
.table-wraping {
  border-radius: 8px;
  padding: 30px 20px;
  width: 100%;
  background-color: #e8e8e875;
}
.icon-wrpp {
  margin-bottom: -28px !important;
  justify-content: end;
  display: flex;
  margin-top: 50px;
  margin-bottom: 0;
}
.list-name {
  font-size: 14px;
  font-weight: 600;
}
.label-succes {
  width: 3px;
  height: 17px;
  background-color: #5b57b1;
  margin-top: 3px;
  margin-left: 18px;
}
.label-notification {
  width: 3px;
  height: 17px;
  background-color: #f73859;
  margin-top: 3px;
}
.success-level {
  font-size: 14px;
}
.notification-lable {
  font-size: 14px;
}
.user-icon i {
  width: 30px;
  text-align: center;
  border-radius: 50%;
  height: 30px;
  font-size: 12px;
  background-color: #0091ff;
  color: #fff;
  line-height: 22px;
}
.purchase-wrap,
.purchase-wrap-grid {
  overflow-x: auto;
  position: relative;
}
.view-icon i {
  font-size: 12px;
  color: #fff;
  width: 30px;
  line-height: 21px;
  text-align: center;
  height: 30px;
  border-radius: 50%;
}
.download-icon i {
  font-size: 12px;
  color: #fff;
  width: 30px;
  line-height: 22px;
  text-align: center;
  height: 30px;
  border-radius: 50%;
}
.btn-wrap {
  border: 0;
  background-color: transparent;
  color: #0091ff;
}
.view-download-icon {
  display: inline-block;
  display: flex;
  padding-top: 50px;
}
th,
td {
  padding: 10px;
}
@media (min-width: 1200px) {
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .user-icon span {
    line-height: 17px;
  }
}
@media (max-width: 768px) {
  .purchase-list-wrap {
    padding: 0 20px;
  }
}
@media (max-width: 350px) {
  .user-icon i[data-v-3bb6fd91] {
    width: 30px;
    text-align: center;
    border-radius: 50%;
    height: 30px;
    font-size: 12px;
    background-color: #0091ff;
    color: #fff;
    line-height: 23px;
  }
  .user-icon span {
    font-size: 12px;
    text-align: center;
    font-weight: 700;
    line-height: 23px;
  }
}
</style>
